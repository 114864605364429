// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBTZCWCg-xMEVY0Xun2w2W0-BVji6VUvlk",
    authDomain: "cpc-dev2.firebaseapp.com",
    databaseURL: "https://cpc-dev2-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "cpc-dev2",
    storageBucket: "cpc-dev2.appspot.com",
    messagingSenderId: "66692869916",
    appId: "1:66692869916:web:460ca76b1e7c510db654d9"
  },
  userApi: 'https://europe-west1-cpc-dev2.cloudfunctions.net/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
