import {Component, Inject, OnInit, Optional, ViewEncapsulation} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {MAT_DIALOG_DATA,  MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {Login} from "../models/loginvalidation";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<LoginComponent>,
    private afAuth: AngularFireAuth
  ) {
  }
  
  ngOnInit() {
    console.log("ngOnInit LoginCom"); 
    this.dialogRef.updatePosition({ top: `30px`});
    this.form = this.fb.group({
      username: ['', Validators.email],
      password: ['', Validators.required]
    });

  }

async onSubmit() {
  this.loginInvalid = false;
  this.formSubmitAttempt = false;
  if (this.form.valid) {
    try {
      const username = this.form.get('username').value;
      const password = this.form.get('password').value;
      await this.afAuth.signInWithEmailAndPassword(username, password);
    } catch (err) {
      this.loginInvalid = true;
    }
  } else {
    this.formSubmitAttempt = true;
  }
  if (! this.loginInvalid)
  {
    this.dialogRef.close();
  }
 

}

}
