import { SettingsService } from './../../settings/services/settings.service';
import { Component, OnInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { SettingsActiveGroup } from '../../settings/models/settingsActiveGroup';
import { AngularFireAuth } from '@angular/fire/auth';
import { switchMap, filter, tap, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './../../confirmation-dialog/confirmation-dialog/confirmation-dialog.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  groups$: Observable<SettingsActiveGroup[]>;
  isLoading = true;
  
  constructor(
    private settingsService: SettingsService,
    private afAuth: AngularFireAuth,
    private _router: Router,
    public dialog: MatDialog,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    console.log("YYYYY")
    this.cookieService.set('last-page-visited', 'groups');
    this.getActiveUsers(); 
    //this.groups$ = this.settingsService.settingsActiveGroups$;
  }
  
  reloadComponent() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/groups']);
  }

  public getActiveUsers() {
    console.log("getActiveUsers !!")
    let myArray = []
    this.settingsService.settingsActiveGroups$.pipe(
      map((val) => {
        val.forEach(val2 => {
                console.log(val2)
                myArray.push(val2)

                //this.groups$ = of(val);
         });
         this.isLoading = false;   
         this.groups$ = of(myArray);
       // return val
      })  

      
    ).subscribe()

  } 

}
