import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from './help/help.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { MatFormFieldModule } from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';

const routes: Routes = [{
  path: 'help', component: HelpComponent
}];

@NgModule({
  declarations: [
    HelpComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes), 
    MatCardModule,
    MatGridListModule
  ],
  exports: [HelpComponent] 
})
export class HelpModule {

  constructor(

  ) {

  }

}