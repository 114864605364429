import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from './DialogData';
import {FormBuilder, Validators, FormGroup, FormControl} from "@angular/forms";
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { switchMap, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from './../../users/services/user.service';
import { User } from './../../users/models/user';
import {CommonModule } from '@angular/common';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  
  newuserform: FormGroup;
  titleAlert: string = 'This field is required';
  post: any = '';
  users$: Observable<User[]>;
  public userEmails: string[]
  public userData: User[]
  constructor(
      private fb: FormBuilder,
      public dialog: MatDialog,
      private dialogRef: MatDialogRef<NewUserComponent>,
      private userService: UserService,
      @Inject(MAT_DIALOG_DATA) data) {

  }

  ngOnInit() {
      this.dialogRef.updatePosition({ top: `3px`});
      this.users$ = this.userService.users$;
      this.readUserEmails();
      this.createForm();
  }

  createForm() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.newuserform = this.fb.group({
      options: ['1'],
      'email': [null, [Validators.required, Validators.pattern(emailregex)], this.ValidateEmail.bind(this)],
      'fullname': [null, Validators.required],
      'groupname': [null, Validators.required],
      'password': [null, [Validators.required, this.checkPassword]],
    });
  }

  setChangeValidate() {
    this.newuserform.get('validate').valueChanges.subscribe(
      (validate) => {
        if (validate == '1') {
          this.newuserform.get('fullname').setValidators([Validators.required, Validators.minLength(3)]);
          this.titleAlert = "You need to specify at least 3 characters";
        } else {
          this.newuserform.get('fullname').setValidators(Validators.required);
        }
        this.newuserform.get('fullname').updateValueAndValidity();
      }
    )
  }

  checkPassword(control) {
    let enteredPassword = control.value
    let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
  }

  get fullname() {
    return this.newuserform.get('fullname') as FormControl
  }

  get groupname() {
    return this.newuserform.get('groupname') as FormControl
  }
  
 readUserEmails()
{
  console.log("test!")
  //console.log(control.value)
  this.userService.userEmails$().pipe(
    map((val) => {
      this.userEmails = val;
      console.log(this.userEmails)
      return val
    })  
  ).subscribe();    
}

 ValidateEmail(control: AbstractControl): {[key: string]: any} | null  {
  console.log("validateEmail")
  return new Observable(observer => {
    let result = (this.userEmails.indexOf(control.value) !== -1) ? { 'alreadyInUse': true } : null;
      observer.next(result);
      observer.complete();
  })
}

  getErrorEmail() {
    return this.newuserform.get('email').hasError('required') ? 'Field is required' :
      this.newuserform.get('email').hasError('pattern') ? 'Not a valid emailaddress' :
        this.newuserform.get('email').hasError('alreadyInUse') ? 'This emailaddress is already in use' : '';
  }

  getErrorPassword() {
    return this.newuserform.get('password').hasError('required') ? 'Field is required (at least eight characters, one uppercase letter and one number)' :
      this.newuserform.get('password').hasError('requirements') ? 'Password needs to be at least eight characters, one uppercase letter and one number' : '';
  }

  onNoClick(): void {
    console.log("cancel !!")
      this.dialogRef.close();
  }

  onSubmit(post) {
    console.log("onSubmit")
    console.log(post)
    this.post = post;
    this.dialogRef.close(this.newuserform.value);
  }

}
