import { TripByDateService } from './../services/trip-by-date.service';
import { AuthService } from './../../auth/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { TripByDate } from '../models/tripbydate';
import { switchMap, filter } from 'rxjs/operators';
import { MatCardModule } from '@angular/material/card';
import { CookieService } from 'ngx-cookie-service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router'



@Component({
  selector: 'app-trips-by-date-excel',
  templateUrl: './trips-by-date-all.component.html',
  styleUrls: ['./trips-by-date-all.component.scss']
})

export class TripsByDateAllComponent implements OnInit {
  tripsByDateAll$: Observable<TripByDate[]>;
  fileName= 'CarTrack';  
  fileExtension= '.xlsx';  

  displayedColumns: string[] = ['driver', 'passenger1', 'passenger2','passenger3','passenger4','passenger1_email', 'passenger2_email','passenger3_email','passenger4_email', 'date', 'starttime', 'endtime', 'ownerpresent', 'carmodel', 'licenseplate'];

  myFirstGroup$: Observable<string>;
  appUserAgentCheck: Boolean;

  id: string;
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private tripsByDateService: TripByDateService,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private cookieService: CookieService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    console.log("ngOn tripsByDateAll")
    this.id = this.route.snapshot.paramMap.get('id')
    console.log(this.id)
    this.cookieService.set('last-page-visited', 'countbydate');
    const userAgent = window.navigator.userAgent;
    this.appUserAgentCheck = userAgent.includes("carpoolcommute");
    console.log(this.appUserAgentCheck);
    //console.log(this.tripsByCountService.tripbycount$)
    this.myFirstGroup$ = this.authService.myfirstgroup$;
    console.log(this.authService.myfirstgroup$)
    //this.tripsByCount$ = this.tripsByCountService.tripbycount$;
    if (this.id == null)
    {
      console.log("logged in groups")
      this.getThisUserTripsDateAll(); 
    }
    else
    {
      console.log("Admin groups")
      this.getTripsDateAll(this.id); 
    }
    //this.getTripsDateAll();    
  }


  public getThisUserTripsDateAll() {
    this.authService.myfirstgroup$.pipe(
     switchMap(group => 
      this.tripsByDateService.alltripsbydate$(group))
  )
  .subscribe(res => {
    this.isLoading = false;   
    this.tripsByDateAll$ = of(res);
  });
  }

  public getTripsDateAll(group: string ) {
    this.tripsByDateService.alltripsbydate$(group).pipe(
 
      ).subscribe(res => {
        this.isLoading = false;   
        this.tripsByDateAll$ = of(res);
      });
      

    } 
  
  getTimeRecord(row)
  {
    console.log(row)
    let message = "Start: " + row.starttime + "\nStop: " + row.endtime;
    this._snackBar.open(message, 'Undo', {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  getCarRecord(row)
  {
    console.log(row)
    let message = "Start: " + row.starttime + "\nStop: " + row.endtime;
    this._snackBar.open(message, 'Undo', {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       const timeElapsed = Date.now();
       const today = new Date(timeElapsed);
       const myDateString = today.toUTCString()
       
       let fileInfo = ''
       if (this.id != null) {
         fileInfo =  this.fileName + "_" + this.id + "_" + myDateString + this.fileExtension
       } else {
          fileInfo = this.fileName + "_" + myDateString + this.fileExtension
       }

       /* save to file */
       XLSX.writeFile(wb, fileInfo);
			
    }
}


