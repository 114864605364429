import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users/users.component';
//import { UserFormComponent } from './user-form/user-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { FormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './../confirmation-dialog/confirmation-dialog/confirmation-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips'
import { MustMatchDirective } from './user-form/must-match.directive';
import {MatListModule} from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';


@NgModule({
  declarations: [
    UsersComponent,
    //UserFormComponent,
    ConfirmationDialogComponent,
    MustMatchDirective
  ],
  entryComponents: [
    //UserFormComponent,    
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    MatListModule,
    MatButtonModule,
    MatChipsModule,
    MatCardModule,
    MatDialogModule
  ]
})
export class UsersModule { }
