import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsCombinedComponent } from './settings/settings-combined.component';

const routes: Routes = [
  {path: 'combinedsettings', component: SettingsCombinedComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule] 
})
export class SettingsRoutingModule {

  constructor(

  ) {

  }

}
