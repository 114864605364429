import { Injectable } from '@angular/core';
import { map,switchMap } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { JwtHelperService } from '@auth0/angular-jwt';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private afAuth: AngularFireAuth,
  ) { }

  getAuth() { 
    return this.afAuth; 
  }

  /** 
   * Initiate the password reset process for this user 
   * @param email email of the user 
   */ 
  resetPasswordInit(email: string) { 
    console.log("Reset Password email request: ")
    return this.afAuth.sendPasswordResetEmail(email);
    //, 
    //  { url: 'http://localhost:4200/auth' }); 
    } 

  get myfirstgroup$(): Observable<string> {
    return this.afAuth.authState.pipe(
        switchMap((user) => {
            if (user) {
              return from(user.getIdToken())
            }
            return of(null);
          }),
          map(result => {
            console.log(this.getFirstGroupFromToken(result));
            return this.getFirstGroupFromToken(result);
          })
    )    
  }

  getFirstGroupFromToken(token: string): string {
    if (token) {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);
        return(decodedToken.groups[0])
    }
    return(null)
  }
  
}
