import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import SettingsJson from '../../../assets/appsettings.json';
import { of, from} from 'rxjs';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  appMode$: Observable<string>;

  constructor(
    private _router: Router,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.cookieService.set('last-page-visited', 'about');
    console.log(SettingsJson.app)
    this.appMode$ = of(SettingsJson.app);
  }

  reloadComponent() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/about']);
  }

}


