import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SettingsMy } from '../models/settingsMy';
import { SettingsGroup } from '../models/settingsGroup';
import { SettingsActiveGroup } from '../models/settingsActiveGroup';
import SettingsJson from '../../../assets/appsettings.json';

export type NewUserSettingRequest = { 
  Active: string, 
  Options: string,
  CurrentUser: string, 
  Email: string, 
  Include: string, 
  Name: string, 
  SendEmail: string, 
  ShortName: string, 
  UserId: string, 
  TrafficLocation: string, 
  LicensePlate: string,
  CarModel: string, 
  Uuid: string, 
  UuidMajor: string, 
  UuidMinor: string, 
};

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpClient
    
  ) {
    console.log("constructor: ")
    //console.log(SettingsJson);
   }
   
   get settingsmy$(): Observable<SettingsMy[]> { 
    let userBaseUrl = SettingsJson.baseUrl + SettingsJson.userUrl; 
    console.log("settingsmy userBaseUrl: " + userBaseUrl) 
    return this.http.get<{settings: SettingsMy[]}>(`${userBaseUrl}`).pipe(
      tap(val => console.log(`settingsmy BEFORE MAP: ${JSON.stringify(val)}`)),
      map(result => {
        //console.log(result.settings)
        return result.settings;
      })
    );
  }

  get settingsgroup$(): Observable<SettingsGroup[]> { 
    let groupBaseUrl = SettingsJson.baseUrl + SettingsJson.groupUrl; 
    console.log("groupBaseUrl: " + groupBaseUrl)  
    return this.http.get<{settings: SettingsGroup[]}>(`${groupBaseUrl}`).pipe(
      tap(val => console.log(`settingsgroup BEFORE MAP: ${JSON.stringify(val)}`)),
      map(result => {
        console.log(result.settings)
        return result.settings;
      })
    );
  }

  settingsgroup2$(group: any): Observable<SettingsGroup[]> { 
    const params = new HttpParams().set('group', group);
    let groupBaseUrl = SettingsJson.baseUrl + SettingsJson.groupUrl; 
    console.log("groupBaseUrl: " + groupBaseUrl)  
    return this.http.get<{settings: SettingsGroup[]}>(`${groupBaseUrl}`, {params}).pipe(
      tap(val => console.log(`settingsgroup BEFORE MAP: ${JSON.stringify(val)}`)),
      map(result => {
        console.log(result.settings)
        return result.settings;
      })
    );
  }

  get settingsActiveGroups$(): Observable<SettingsActiveGroup[]> { 
    let activeGroupsUrl = SettingsJson.baseUrl + SettingsJson.activeGroupsUrl; 
    console.log("activeGroupsUrl: " + activeGroupsUrl)  
    return this.http.get<{groups: SettingsActiveGroup[]}>(`${activeGroupsUrl}`).pipe(
      tap(val => console.log(`settingsActiveGroups BEFORE MAP: ${JSON.stringify(val)}`)),
      map(result => {
        console.log(result)
        return result.groups;
      })
    );
  }


  get settingsscan$(): Observable<SettingsGroup[]> { 
    let scanBaseUrl = SettingsJson.baseUrl + SettingsJson.scanUrl; 
    console.log("scanBaseUrl: " + scanBaseUrl)   
    return this.http.get<{settings: SettingsGroup[]}>(`${scanBaseUrl}`).pipe(
      tap(val => console.log(`settingsscan BEFORE MAP: ${JSON.stringify(val)}`)),
      map(result => {
        console.log(result.settings)
        return result.settings;
      })
    );
  }

  CreateNewUserSetting(user: NewUserSettingRequest, group: any) {
    const params = new HttpParams().set('group', group);
    console.log("Group: " + group)
    console.log("newUserSettingRequest: " + JSON.stringify(user));
    let newUserBaseUrl = SettingsJson.baseUrl + SettingsJson.newUserUrl; 
    console.log("newUserBaseUrl: " + newUserBaseUrl)   
    return this.http.post(`${newUserBaseUrl}`, user, {params}).pipe(
      map(_ => { })
    );
  }

  DeleteUserSetting(user: string, group: any) {
    let myUser = {
      UserId: user
    }
    const params = new HttpParams().set('group', group);
    console.log("Group: " + group)
    console.log("Id: " + user);
    let deleteUserBaseUrl = SettingsJson.baseUrl + SettingsJson.deleteUserUrl; 
    console.log("deleteUserBaseUrl: " + deleteUserBaseUrl)   
    
    return this.http.post(`${deleteUserBaseUrl}`, myUser,{params}).pipe(
      map(_ => { })
    );
  }

  editUserSetting(user: string, group: any, shortname: any, uuidminor: any, trafficlocation: any, licenseplate: any, carmodel: any) {
    let myUser = {
      UserId: user,
      ShortName: shortname,
      UuidMinor: uuidminor,
      TrafficLocation: trafficlocation,
      LicensePlate: licenseplate,
      CarModel: carmodel
    }
    const params = new HttpParams().set('group', group);
    console.log("Group: " + group)
    console.log("Id: " + user);
    console.log("License: " + licenseplate);

    let userBaseUrl = SettingsJson.baseUrl + SettingsJson.userUrl; 
    console.log("userBaseUrl: " + userBaseUrl)   
    
    return this.http.patch(`${userBaseUrl}`, myUser,{params}).pipe(
      map(_ => { })
    );
  }

  
}
