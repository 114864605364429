import { TripByDateService } from './../services/trip-by-date.service';
import { AuthService } from './../../auth/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { TripByUser } from '../models/tripbyuser';
import { map, tap } from 'rxjs/operators';
import { switchMap, filter } from 'rxjs/operators';
import { MatCardModule } from '@angular/material/card';
import { CookieService } from 'ngx-cookie-service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';
import { ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router'


@Component({
  selector: 'app-trips-by-user',
  templateUrl: './trips-by-user.component.html',
  styleUrls: ['./trips-by-user.component.scss']
})

export class TripsByUserComponent implements OnInit {

  tripsbyuser$: Observable<TripByUser[]>;

  displayedColumns: string[] = ['carname', 'count', 'first_event_date', 'first_event_time', 'last_event_time'];

  myFirstGroup$: Observable<string>;
  appUserAgentCheck: Boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource()

  isLoading = true;
  id: string;

  constructor(
    private route: ActivatedRoute,
    private tripsByDateService: TripByDateService,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private cookieService: CookieService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    console.log("ngOn tripsbymyuser")
    this.id = this.route.snapshot.paramMap.get('id')
    console.log(this.id)
    this.cookieService.set('last-page-visited', 'countbymyuser');
    const userAgent = window.navigator.userAgent;
    this.appUserAgentCheck = userAgent.includes("carpoolcommute");
    console.log(this.appUserAgentCheck);
    
    if (this.id == null)
    {
      console.log("logged in groups")
      this.getUserDateAll("","");
      //this.getThisMyUserTripsDateAll(); 
    }
    else
    {
      console.log("Admin groups")
      //this.getTripsDateAll2(this.id); 
    }
   
  }

  getRecord(row)
  {
    console.log(row)
    let message = row.carmodel + ", " + row.licenseplate;
    this._snackBar.open(message, 'Undo', {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  public getUserDateAll(group: string, uid: string ) {
    this.tripsByDateService.alltripsbyMyUser$(group, uid).pipe(
      tap(val => console.log(`getUserDateAll: ${JSON.stringify(val)}`)),
      map((val) => {
        this.isLoading = false;   
        this.dataSource.paginator = this.paginator;
        this.dataSource.data = val; 
      }),
      ).subscribe()
    }
}


