import { SettingsService } from './../services/settings.service';
import { UserService } from './../../users/services/user.service';
//import { AuthService } from './../../auth/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsGroup } from '../models/settingsGroup';
import { SettingsMy } from '../models/settingsMy';
import { switchMap, filter } from 'rxjs/operators';
//import { MatCardModule } from '@angular/material/card';
//import { MatButtonModule } from '@angular/material/button';
import { User } from './../../users/models/user';
//import { UserFormService } from './../../users/services/user-form.service';
//import { UserFormComponent } from './../../users/user-form/user-form.component';
//import { UserFormEditUserComponent } from './../../users/user-form/user-form-edit-user.component';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { EditSettingsComponent } from '../../edit-settings/edit-settings/edit-settings.component';
import SettingsJson from '../../../assets/appsettings.json';
import { ActivatedRoute } from '@angular/router'




@Component({
  selector: 'app-trips',
  templateUrl: './settings-combined.component.html',
  styleUrls: ['./settings-combined.component.scss']
})

export class SettingsCombinedComponent implements OnInit {

  settingsGroup$: Observable<SettingsGroup[]>;
  settingsGroup2$: Observable<SettingsGroup[]>;
  settingsMy$: Observable<SettingsMy[]>;
  adminUser$: Observable<boolean>;
  user$: Observable<User>;
  appMode$: Observable<string>;
  id: string;
  myUser: string;
  

  displayedColumns: string[] = ['GroupName', 'Name', 'ShortName', 'UuidMajor', 'UuidMinor', 'Email'];
  //displayedColumns: string[] = ['driver', 'passenger1', 'passenger2', 'count', 'date', 'nextToDrive'];
  

  isLoading = true;

  tableDef: Array<any> = [
    {
      key: 'Name',
      header: 'Name',
      className: 'something'
    },    {
      key: 'ShortName',
      header: 'Short Name',
      className: 'anElement'
    },    {
      key: 'GroupName',
      header: 'Group Name',
      className: 'number'
    },    {
      key: 'UuidMajor',
      header: 'Uuid Major',
      className: 'number'
    },    {
      key: 'UuidMinor',
      header: 'Uuid Minor',
      className: 'number'
    },    {
        key: 'Email',
        header: 'Email',
        className: 'number'
      },  
  ]

  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    //private authService: AuthService,
    private afAuth: AngularFireAuth,
    //private userForm: UserFormService,
    //private modal: NgbModal,
    private _router: Router,
    private cookieService: CookieService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    console.log("ngOn Settings combined")
    this.id = this.route.snapshot.paramMap.get('id')
    console.log(this.id)
    console.log(SettingsJson.app)
    this.appMode$ = of(SettingsJson.app);
    this.cookieService.set('last-page-visited', 'settings');
    
    this.user$ = this.afAuth.user.pipe(
      filter(user => !!user),
      switchMap(user => 
        this.userService.user$(user.uid)), 
    );
        
    this.settingsGroup$ = this.settingsService.settingsgroup$;

    if (this.id == null)
    {
      console.log("logged in groups")
      this.id = '';
      this.getSettings('', SettingsJson.app); 
    }
    else
    {
      console.log("Admin groups")
      this.getSettings(this.id, SettingsJson.app); 
    }
    this.isLoading = false;   
  }

  reloadComponent(id: string) {
    console.log("reloadComponent:")
    console.log(id);
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/combinedsettings',{ id: id }]);
  }

  create() {
    let group = "test1"
    let myUser = {
      Active: "1",
      Options: "1",
      CurrentUser: "NA",
      Email: "magnus.mikkelsen@porsevej.dk",
      Include: "0",
      Name: "Magnus Mikkelsen",
      SendEmail: "0",
      ShortName: "MM",
      UserId: "EvLzHL1fvVO9zN4Z4izrg8IL9Mh1",
      Uuid: "1c4e0347-a8df-41a2-86ac-74ac19713d02",
      TrafficLocation: "-34.397, 150.644",
      LicensePlate: "YY77777",
      CarModel: "Peugeot 308",
      UuidMajor: "1",
      UuidMinor: "6"
  }
    
      this.settingsService.CreateNewUserSetting(myUser,group).subscribe(_ => {
        console.log('user created');
      });   
}

public getSettings(group: string, appMode: string) {
  console.log("getSettings !!")
  let myArray = []
  this.settingsService.settingsgroup2$(group).pipe(
    map((val) => {
      val.forEach(val2 => {
        if (group == '') {
          if (appMode == 'cartrack') {
            if (val2.CurrentUser == val2.UserId) {
              myArray.push(val2)
            } else
            {
              if (val2.Options == '2') {
                myArray.push(val2)
              }
            }        
          } else {
            myArray.push(val2)
          }
            
        } else {
          myArray.push(val2)
        }
       });
       this.settingsGroup2$ = of(myArray);
    })  
  ).subscribe()
} 


openEditDialog(userToEdit): void {
  console.log("userToEdit: " + JSON.stringify(userToEdit))
  const dialogRef = this.dialog.open(EditSettingsComponent, {
    minWidth: '20vw',
    data: {userid: userToEdit.UserId, groupid: userToEdit.GroupId, displayname: userToEdit.Name, shortname: userToEdit.ShortName, usertype: userToEdit.Options,
      uuidminor:userToEdit.UuidMinor, trafficlocation:userToEdit.TrafficLocation, licenseplate :userToEdit.LicensePlate, carmodel :userToEdit.CarModel}
  });

  dialogRef.afterClosed().subscribe(result => {
    
    console.log("Saved result: " + JSON.stringify(result))
 
    this.settingsService.editUserSetting(result.UserId, result.GroupId, result.ShortName, result.UuidMinor, result.TrafficLocation, result.LicensePlate, result.CarModel).subscribe(_ => {
    console.log('setting edited');
    this.reloadComponent(this.id);
  }); 

  });
  
}

convertOptionVal(input: string)
{
  if (input == '1')
    return "Standard user" 
  if (input == '2')
    return "Ressource" 
  return "Unknown"  
}

getGroup()
{
  let myGroup = ''
  if (this.id != '')
  {
    myGroup = this.id
  } else {
   // myGroup = this.user$
  }
  return myGroup  
}



}


