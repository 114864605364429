import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { of, from} from 'rxjs';
import SettingsJson from '../../../assets/appsettings.json';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  secondThemeSelected : boolean = true;
  isDone = false;

  appMode$: Observable<string>;

  constructor() { }


  ngOnInit(): void {
    var userAgent = navigator.userAgent;
    console.log("Useragent: " + userAgent);

    console.log(SettingsJson.app)
    this.appMode$ = of(SettingsJson.app);

    if (userAgent.includes("carpoolcommute"))
    {
      console.log("carpoolcommute app:  ");
      this.secondThemeSelected = false;

      if (userAgent == "carpoolcommute1")
      {
        this.secondThemeSelected = false;
      }

      if (userAgent == "carpoolcommute2")
      {
        this.secondThemeSelected = true;
      }
    }
    else
    {
      console.log("Not carpoolcommute app: ");
      this.secondThemeSelected = false;
    }
  }

}
