import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnInit } from '@angular/core';
import { ViewChild, HostListener } from '@angular/core';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login/login.component';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
//import { MatSidenav } from '@angular/material/sidenav';
import { of, from} from 'rxjs';
import { take } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SettingsGroup } from './settings/models/settingsGroup';
import { SettingsService } from './settings/services/settings.service';
import { CookieService } from 'ngx-cookie-service';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Inject } from "@angular/core";
import SettingsJson from '../assets/appsettings.json';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  secondThemeSelected : boolean = true;
  public screenWidth: any;
  public screenHeight: any;
  title = 'angulartitle';

  opened = true;
  //@ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  loggedIn$: Observable<boolean>;
  loggedOut$: Observable<boolean>;
  token$: Observable<string | null>;
  loggedInUserName$: Observable<string | null>;
  loggedInUserFirstName$: Observable<string | null>;
  role$: Observable<string>;
  firstGroup$: Observable<string>;
  adminUser$: Observable<boolean>;
  settingsGroup$: Observable<SettingsGroup[]>;
  settingsScan$: Observable<SettingsGroup[]>;
  private cookieValue : string;
  appUserAgentCheck: Boolean;

  appMode$: Observable<string>;
  
  constructor(
    //private modal: NgbModal,
    private afAuth: AngularFireAuth,
    private router: Router,
    private settingsService: SettingsService,
    private cookieService: CookieService,
    private matDialog: MatDialog,
    private titleService: Title,
    public dialog: MatDialog

  ) {

  }

  setDocTitle(title: string) {
    //console.log('current title:::::' + this.titleService.getTitle());
    this.titleService.setTitle(title);
 }

  ngOnInit() {
    const userAgent = window.navigator.userAgent;
    this.appUserAgentCheck = userAgent.includes("carpoolcommute");
    console.log(this.appUserAgentCheck);

    console.log(SettingsJson.app)
    this.appMode$ = of(SettingsJson.app);

   // var userAgent = navigator.userAgent;
   // console.log("Useragent: " + userAgent);
    this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
      console.log("screenszie width: " + window.innerWidth);  
      console.log("screenszie hight: " + window.innerHeight);  
    

    if (userAgent.includes("carpoolcommute"))
    {
      console.log("carpoolcommute app:  ");
      this.secondThemeSelected = false;

      if (userAgent == "carpoolcommute1")
      {
        console.log("carpoolcommute1 app:  ");
        this.secondThemeSelected = false;
      }

      if (userAgent == "carpoolcommute2")
      {
        console.log("carpoolcommute2 app:  ");
        this.secondThemeSelected = true;
      }
    }
    else
    {
      console.log("Not carpoolcommute app: ");
      this.secondThemeSelected = false;
    }
   
    this.loggedIn$ = this.afAuth.authState.pipe(

      map(user => !!user)
    )

    this.loggedOut$ = this.loggedIn$.pipe(
      map(loggedIn => !loggedIn)
    )

    this.token$ = this.afAuth.authState.pipe(
      //take(1),
      //tap(val => console.log(`BEFORE MAP: ${JSON.stringify(val)}`)),
      switchMap((user) => {
        if (user) {
          console.log("token debug:")
          return from(user.getIdToken())
        }

        return of(null);
      }),
      //tap(val => console.log(`After MAP: ${val}`))
    )

    //this.settingsGroup$ = this.settingsService.settingsgroup$;
    this.settingsScan$ = this.settingsService.settingsscan$;
    this.settingsScan$.subscribe(val => {
      console.log("group-info: " + JSON.stringify(val))
      this.sendToNative(val);

    });
   
    this.token$.subscribe(val => {
      console.log("Token:::")
      if (val) {
      console.log(val);
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(val);
      console.log(decodedToken);
      
      var res = decodedToken.name.split(" ");
      this.loggedInUserFirstName$ = of(res[0]);

      this.role$ = of(decodedToken.role)
      this.loggedInUserName$ = of(decodedToken.name)
      this.firstGroup$ = of(decodedToken.groups[0])
      if (decodedToken.role == 'admin') {
        this.adminUser$ = of(true);
        console.log("Admin");
      }
      else
      {
        this.adminUser$ = of(false);
        console.log("Not Admin");
      }
    }
    else {
      console.log("no token!!!")
      this.role$ = of(null)
      this.firstGroup$ = of(null)
      this.adminUser$ = of(null)
      this.loggedInUserName$ = of(null)
      this.loggedInUserFirstName$ = of(null)
    }
    })
    /*
    if (window.innerWidth < 768) {
      this.sidenav.fixedTopGap = 55;
      this.opened = false;
    } else {
      this.sidenav.fixedTopGap = 55;
      this.opened = true;
    }
    */
  }
  
  applyTheme(secondThemeSelected : boolean)
  {
    this.secondThemeSelected = secondThemeSelected;
  }

  signIn() {
    console.log("Login dialog:")
/*
    var dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
    minWidth: '100vw',
    height: '100vh'
  };
*/
  this.matDialog.open(LoginComponent, {
      minWidth: '20vw',   
    });
  }

  async signOut() {
//Todo
    await this.afAuth.signOut()
    .catch(function(error) { alert(error); });

    //this.email = '';
    //this.password = '';
 
    await this.router.navigateByUrl("/")
  }

  
  isBiggerScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 768) {
      return true;
    } else {
      return false;
    }
  }

  /**
 Send a message to native version of the application
 **/
sendToNative(message){
  // Initiate the handle for Native process

  var userAgent = navigator.userAgent;
	//console.log("Useragent: " + userAgent);				
  //if (userAgent == "carpoolcommute")
  if (userAgent.includes("carpoolcommute"))
  {
    console.log("sendToNative Useragent: " + userAgent)
    var native = (window as any).webkit.messageHandlers.MyObserver
    native.postMessage(JSON.stringify(message))
  }
  
}



}
